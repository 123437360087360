<template>
    <div>
        <div style="height: 20px;"></div>
        <!--详情-->
        <el-row>
            <el-col :span="16">
                <el-form v-loading="loading" size="medium" :label-width="this.env.label_width">

                    <el-form-item label="适用范围">
                        <el-tag size="mini" v-if="info.range_type === 1">全平台</el-tag>
                        <el-tag size="mini" v-if="info.range_type === 2" type="success">
                            {{ info.company_name }}
                        </el-tag>
                    </el-form-item>
                    <el-form-item label="优惠券名称">
                        <span class="form-span-text">{{ info.coupon_title }}</span>
                    </el-form-item>
                    <el-form-item label="优惠券封面">
                        <Qnupload v-if="info.cover" v-model="info.cover" :sum="1" :readonly="true" />
                        <span v-else class="form-span-text">未设置</span>
                    </el-form-item>
                    <el-form-item label="满减条件(￥)">
                        <span class="form-span-text">满￥{{ info.full_money }}可用</span>
                    </el-form-item>
                    <el-form-item label="抵扣金额(￥)">
                        <span class="form-span-text">￥{{ info.coupon_money }}</span>
                    </el-form-item>
                    <el-form-item label="有效期限(天)">
                        <span class="form-span-text" v-if="info.validity_time === 0">无限制</span>
                        <span class="form-span-text" v-else>{{ info.validity_time }}天</span>
                    </el-form-item>
                    <el-form-item label="添加时间">
                        <span class="form-span-text">{{ info.create_time }}</span>
                    </el-form-item>
                    <el-form-item label="门店范围">
                        <el-tag size="mini" v-if="info.store_range === 1">所有门店</el-tag>
                        <el-tag size="mini" v-if="info.store_range === 2" type="success">指定门店</el-tag>
                    </el-form-item>
                    <div v-if="info.store_range === 2">
                        <el-form-item label="指定门店">
                            <el-card class="box-card">
                                <el-tag class="form-tag_colour-tag" :key="index" v-for="(item, index) in info.store_s"
                                    type="info" size="mini" effect="light">
                                    {{ item.store_name }}
                                </el-tag>
                            </el-card>
                        </el-form-item>
                        <el-form-item label="门店品类">
                            <el-card class="box-card">
                                <el-tag :key="index"
                                    v-for="(item, index) in mergeCategory(info.store_motion_category_two, info.store_operate_tag)"
                                    class="form-tag_colour-tag" effect="dark" size="small">
                                    {{ item.name }}
                                </el-tag>
                            </el-card>
                            <span class="form-tip">指定门店和门店标签同时满足才生效，无 则不限制</span>
                        </el-form-item>
                    </div>
                    <el-form-item label="课程范围">
                        <el-tag size="mini" v-if="info.lesson_range === 1">所有课程</el-tag>
                        <el-tag size="mini" v-if="info.lesson_range === 2" type="success">指定课程</el-tag>
                    </el-form-item>
                    <div v-if="info.lesson_range === 2">
                        <el-form-item label="指定课程">
                            <el-card class="box-card">
                                <el-tag class="form-tag_colour-tag" :key="index" v-for="(item, index) in info.lesson_s"
                                    type="info" size="mini" effect="light">
                                    {{ item.lesson_name }}
                                </el-tag>
                            </el-card>
                        </el-form-item>
                        <el-form-item label="课程品类">
                            <el-card class="box-card">
                                <el-tag :key="index"
                                    v-for="(item, index) in mergeCategory(info.lesson_motion_category_two, info.lesson_operate_tag, info.lesson_target_category_zero)"
                                    class="form-tag_colour-tag" effect="dark" size="small">
                                    {{ item.name }}
                                </el-tag>
                            </el-card>
                            <span class=" form-tip">指定课程和课程标签同时满足才生效，无 则不限制</span>
                        </el-form-item>
                    </div>
                    <el-form-item label="教练范围">
                        <el-tag size="mini" v-if="info.coach_range === 1">所有教练</el-tag>
                        <el-tag size="mini" v-if="info.coach_range === 2" type="success">指定教练</el-tag>
                    </el-form-item>
                    <div v-if="info.coach_range === 2">
                        <el-form-item label="指定教练">
                            <el-card class="box-card">
                                <el-tag class="form-tag_colour-tag" :key="index" v-for="(item, index) in info.coach_s"
                                    type="info" size="mini" effect="light">
                                    {{ item.user_nick }}
                                </el-tag>
                            </el-card>
                        </el-form-item>
                        <el-form-item label="教练标签">
                            <el-card class="box-card">
                                <el-tag :key="index" v-for="(item, index) in info.coach_tag_s" class="form-tag_colour-tag"
                                    effect="dark" size="small" :color="item.tag_colour">
                                    {{ item.tag_name }}
                                </el-tag>
                            </el-card>
                            <span class="form-tip">指定课程和课程标签同时满足才生效，无 则不限制</span>
                        </el-form-item>
                    </div>

                    <el-form-item>
                        <el-button size="medium" @click="isreturn">返回</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import Qnupload from '@/components/Qnupload'

export default {
    components: {
        Qnupload,
    },
    data() {
        return {
            loading: true,
            coupon_uuid: '',
            info: {},
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 判断是否为空
        is_empty(auth) {
            return this.Tool.is_empty(auth)
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            let coupon_uuid = this.$route.query.coupon_uuid
            if (coupon_uuid !== undefined) {
                this.coupon_uuid = coupon_uuid
                this.getinfo()
            } else {
                this.$message({
                    message: '无查找对象',
                    duration: this.env.message_error,
                    onClose: () => {
                        this.isreturn()
                    }
                });
            }
        },
        // 优惠券详情
        getinfo() {
            let postdata = {
                api_name: "coupon.library.getinfo",
                token: this.Tool.get_l_cache('token'),
                coupon_uuid: this.coupon_uuid
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.info = json.data
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 返回
        isreturn() {
            this.$router.go(-1)
        },
        mergeCategory(item, item1, item2) {
            let arr = [];
            if (!this.is_empty(item)) {
                arr = arr.concat(item)
            }
            if (!this.is_empty(item1)) {
                arr = arr.concat(item1)
            }
            if (!this.is_empty(item2)) {
                arr = arr.concat(item2)
            }
            return arr;
        },
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
